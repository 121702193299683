import { get, find, isEmpty, isUndefined, isObject, identity } from 'lodash';
import { FEED_PAGE_SECTIONS } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { blogAppDefId } from '../../constants/apps';
import FontBuilder from './font-builder';
import defaultStyles from './default-styles';

export default class BlogWidgetBuilder {
  constructor(oldBlogComponent, props) {
    const {
      widgetId,
      applicationId,
      fontMap,
      styleMap,
      widgetType = BlogWidgetBuilder.TPAWidget,
      overrideHeight = true,
    } = props;
    this.props = props;
    this.widgetId = widgetId;
    this.applicationId = applicationId;
    this.oldBlogComponent = oldBlogComponent;
    this.layout = { ...oldBlogComponent.layout };
    this.isCustomStyle = false;
    this.tpaData = {};
    this.properties = {};
    this.propertiesSource = {};
    this.fontMap = fontMap;
    this.styleMap = styleMap;
    this.widgetType = widgetType;
    this.overrideHeight = overrideHeight;
  }

  static TPAMultisection = 'TPAMultiSection';
  static TPASection = 'TPASection';
  static TPAWidget = 'TPAWidget';

  widgetDataType = {
    [BlogWidgetBuilder.TPAMultisection]: 'TPAMultiSection',
    [BlogWidgetBuilder.TPASection]: 'TPA',
    [BlogWidgetBuilder.TPAWidget]: 'TPAWidget',
  };

  widgetSkin = {
    [BlogWidgetBuilder.TPAMultisection]: 'TPASectionSkin',
    [BlogWidgetBuilder.TPASection]: 'TPASectionSkin',
    [BlogWidgetBuilder.TPAWidget]: 'TPAWidgetSkin',
  };

  widgetStyle = {
    [BlogWidgetBuilder.TPAMultisection]: 'tpas0',
    [BlogWidgetBuilder.TPASection]: 'tpas0',
    [BlogWidgetBuilder.TPAWidget]: 'tpaw0',
  };

  setDimensions({ width, height }) {
    width && (this.layout.width = width);
    height && (this.layout.height = height);
    return this;
  }

  setTpaData(key, value) {
    if (isUndefined(value)) {
      return this;
    }
    this.tpaData[key] = value;
    return this;
  }

  mapValueParam(from, to, options = {}) {
    const value = this.resolveValue(from, options);
    return this.setValueParam(to, value);
  }

  resolveValue(from, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }

    if (isObject(from)) {
      Object.keys(from).forEach((key) => (from[key] = this.interpolate(from[key])));
    } else {
      const [view, fieldId, key] = this.interpolate(from).split('/');
      from = { view, fieldId, key };
    }

    const value = get(this.findParam(from), 'value');
    return this.formatValue(value, options);
  }

  formatValue(value, { defaultValue, valueMap, minValue, maxValue, formatter = identity } = {}) {
    if (isUndefined(value)) {
      return defaultValue;
    }
    value = formatter(valueMap ? valueMap[value] || defaultValue : value);
    if (maxValue) {
      value = Math.min(maxValue, value);
    }
    if (minValue) {
      value = Math.max(minValue, value);
    }
    return value;
  }

  resolveThemeValue(from, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }
    const [view, fieldId, key, prop] = this.interpolate(from).split('/');
    const param = this.findParam({ view, fieldId, key }) || find(defaultStyles, { view, fieldId, key }) || {};
    const style = get(this.styleMap, param.value);
    const value = get(style, `style.properties.${prop}`);
    return this.formatValue(value, options);
  }

  mapLogicParam(from, to, options) {
    return this.mapPath(`data.appLogicParams.${from}.value`, to, options);
  }

  mapViewName(to, options) {
    return this.mapPath('data.viewName', to, options);
  }

  mapPath(from, to, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }
    const value = get(this.oldBlogComponent, from);
    return this.setValueParam(to, this.formatValue(value, options));
  }

  shouldSkip({ when }) {
    if (!when) {
      return false;
    }
    const [path, expected] = when;
    let value = this.resolveValue(path);
    if (isUndefined(value)) {
      value = get(this.oldBlogComponent, path);
    }
    return value !== expected;
  }

  findParam(props) {
    return find(get(this.oldBlogComponent, 'data.appLogicCustomizations', []), props);
  }

  mapFont(to) {
    return new FontBuilder(this, to);
  }

  mapThemeColor(from, to, options = {}) {
    const value = this.resolveThemeValue(from, options);
    if (isUndefined(value)) {
      return this;
    }
    const path = from.split('/');
    path[path.length - 1] = `alpha-${path[path.length - 1]}`;
    const opacity = this.resolveThemeValue(path.join('/'), options);
    return this.setColor(to, value, options.opacity || opacity);
  }

  mapThemeParam(from, to, options = {}) {
    const value = this.resolveThemeValue(from, options);
    if (!isUndefined(value)) {
      this.setValueParam(to, value);
    }
    return this;
  }

  mapColor(from, to, options = {}) {
    const value = this.resolveValue(from, options);
    if (isUndefined(value)) {
      return this;
    }
    return this.setColor(to, value, options.opacity);
  }

  setColor(to, value, opacity) {
    if (Array.isArray(value)) {
      [value, opacity] = value;
    }

    if (/color_/.test(value)) {
      this.setThemeParam(to, value.replace('back', ''));
    } else {
      this.setValueParam(to, value);
    }

    return this.setValueParam(`alpha-${to}`, opacity);
  }

  setValueParam(styleParamName, value) {
    if (isUndefined(value)) {
      return this;
    }
    return this.setParam(styleParamName, value, 'value');
  }

  setThemeParam(styleParamName, value) {
    return this.setParam(styleParamName, value, 'theme');
  }

  setParam(styleParamName, value, source) {
    const params = styleParamName.includes('$(section)')
      ? FEED_PAGE_SECTIONS.map((section) => styleParamName.replace('$(section)', section))
      : [styleParamName];

    params.forEach((paramName) => {
      this.properties[paramName] = value;
      this.propertiesSource[paramName] = source;
      if (/^param_color_/.test(paramName)) {
        this.propertiesSource[`alpha-${paramName}`] = 'value';
      }
    });

    return this;
  }

  interpolate(str) {
    return str.replace(/\$\(viewName\)/g, get(this.oldBlogComponent, 'data.viewName', ''));
  }

  when(path, expected) {
    const value = this.resolveValue(path) || get(this.oldBlogComponent, path);
    const branch = new BlogWidgetBuilder(this.oldBlogComponent, this.props);
    branch.parent = this;
    expected = Array.isArray(expected) ? expected : [expected];
    branch.shouldMergeParent = expected.includes(value);
    return branch;
  }

  value() {
    if (!this.parent) {
      throw new Error('Invalid operation');
    }
    if (this.shouldMergeParent) {
      Object.assign(this.parent.properties, { ...this.properties });
      Object.assign(this.parent.propertiesSource, { ...this.propertiesSource });
      Object.assign(this.parent.tpaData, { ...this.tpaData });
    }
    return this.parent;
  }

  build() {
    const widgetType = this.widgetType;
    const widgetSkin = this.widgetSkin[widgetType];
    const widgetDataType = this.widgetDataType[widgetType];
    this.style = this.widgetStyle[widgetType];

    if (!isEmpty(this.properties)) {
      this.style = {
        type: 'ComponentStyle',
        styleType: 'custom',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: this.properties,
          propertiesSource: this.propertiesSource,
          groups: {},
        },
        componentClassName: `wysiwyg.viewer.components.tpapps.${widgetType}`,
        skin: `wysiwyg.viewer.skins.${widgetSkin}`,
      };
    }

    const componentDefinition = {
      type: 'Component',
      skin: `wysiwyg.viewer.skins.${widgetSkin}`,
      componentType: `wysiwyg.viewer.components.tpapps.${widgetType}`,
      layout: this.layout,
      data: {
        widgetId: this.widgetId,
        applicationId: this.applicationId,
        appDefinitionId: blogAppDefId,
        type: widgetDataType,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
      },
      mobileHints: {
        type: 'MobileHints',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
      },
      style: this.style,
    };

    if (!isEmpty(this.tpaData)) {
      componentDefinition.data.tpaData = {
        type: 'TPAData',
        content: JSON.stringify(this.tpaData),
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
      };
    }

    this.overrideHeight && (componentDefinition.layout.height = 2000);

    return componentDefinition;
  }
}
