import { get } from 'lodash';
import experiments from './experiments';
import retry, { repeat } from './retry';
import { RELATIVE_API_URL } from '../constants/api';
import { oldBlogAppDefId } from '../constants/apps';
import { NEW_BLOG_QUICK_MIGRATION, NEW_BLOG_MAGIC_MIGRATION } from '../constants/experiments';
import MagicMigration from './magic-migration';

const MIGRATION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  CONTENT_SYNC_STARTED: 'CONTENT_SYNC_STARTED',
  CONTENT_SYNC_FAILED: 'CONTENT_SYNC_FAILED',
  CONTENT_SYNC_COMPLETE: 'CONTENT_SYNC_COMPLETE',
  REDIRECTS_SET_COMPLETE: 'REDIRECTS_SET_COMPLETE',
};

const getIsOldBlogInstalled = async (sdk) => {
  const componentsRef = await sdk.document.components.getAllComponents('');

  const componentsData = await Promise.all(
    componentsRef.map((componentRef) => sdk.document.components.data.get('', { componentRef })),
  );

  const oldBlogPageData = componentsData.find((componentData) => get(componentData, 'appPageId') === oldBlogAppDefId);

  return Boolean(oldBlogPageData);
};

const shouldMigrateOldBlog = async ({ sdk, isADI }) => {
  if (isADI) {
    return false;
  }

  await Promise.all([
    experiments.conductSingle(NEW_BLOG_QUICK_MIGRATION, 'old'),
    experiments.conductSingle(NEW_BLOG_MAGIC_MIGRATION, 'old'),
  ]);

  const canMigrate =
    experiments.isEnabled(NEW_BLOG_QUICK_MIGRATION, 'new') || experiments.isEnabled(NEW_BLOG_MAGIC_MIGRATION, 'new');

  if (!canMigrate) {
    return false;
  }

  const isOldBlogInstalled = await getIsOldBlogInstalled(sdk);
  if (!isOldBlogInstalled) {
    return false;
  }

  return true;
};

const getContentMigrationApiUrl = () => `${RELATIVE_API_URL}/_api/content-migration`;

const startMigration = (instance) =>
  fetch(`${getContentMigrationApiUrl()}/transfer-content?viewMode=editor&redirectOnTransferCompletion=true`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', instance },
  })
    .then((res) => {
      if (res.status === 404) {
        throw new Error(`Blog not found: ${instance}`);
      }
      return res.ok;
    })
    .catch((err) => {
      throw err;
    });

const getMigrationStatus = (instance) =>
  fetch(`${getContentMigrationApiUrl()}/details?viewMode=editor`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', instance },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res.json();
    })
    .then(({ status }) => status);

const checkMigrationStatus = (instance) => async (stopChecking) => {
  const status = await retry(() => getMigrationStatus(instance), 3);
  if ([MIGRATION_STATUS.CONTENT_SYNC_FAILED, MIGRATION_STATUS.REDIRECTS_SET_COMPLETE].includes(status)) {
    stopChecking();
  }
  return status;
};

const migrateOldBlog = async (context) => {
  return new Promise(async (resolve) => {
    const instance = await context.sdk.document.info.getAppInstance('');

    let status;
    try {
      await retry(() => startMigration(instance), 6, 5000);

      const delay = 15000;
      const times = 80;
      status = await repeat(checkMigrationStatus(instance), times, delay);
    } catch (_) {}

    if (experiments.isEnabled(NEW_BLOG_MAGIC_MIGRATION, 'new')) {
      await new MagicMigration(context).run();
    }

    resolve(status);
  });
};

export default { shouldMigrate: shouldMigrateOldBlog, migrate: migrateOldBlog };
